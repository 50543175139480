<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.subPage"/>
      <a-card :bordered="false" v-else>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务单号">
                  <a-input v-model.trim="queryParam.quota_num" allowClear placeholder="根据业务单号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-model-item label="业务类型">
                  <a-select
                    showSearch
                    allowClear
                    v-model="queryParam.bill_type"
                  >
                    <a-select-option v-for="d in billTypeOps" :key="d.value">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-model-item label="调整状态">
                  <a-select
                    showSearch
                    allowClear
                    v-model="queryParam.adjust_status"
                  >
                    <a-select-option v-for="d in adjustStatusOps" :key="d.code">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="创建时间">
                  <a-range-picker @change="onDateChange"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="申请时间">
                  <a-range-picker @change="onApplyDateChange"/>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <div class="table-operator">
          <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
          <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        </div>

        <s-table
          ref="table"
          size="small"
          rowKey="id"
          :columns="columns"
          :data="loadData"
          :scroll="{ x: 'max-content' }">
          <div slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </div>
          <span slot="adjustTypeInfoSlot" slot-scope="text, record">
            <div v-for="item in record.sale_adjust_details" :key="item.id">
              <a-tag :color="adjustOrderTypeColor(item.adjust_type)" style="margin-bottom:2px;">
                {{ item.adjust_type }}
              </a-tag>
              <span>{{item.original_invoices}}</span><span v-if="item.new_invoices">-->{{item.new_invoices}}</span>
              <span v-if="item.new_customer_name">{{item.original_customer_name}}-->{{item.new_customer_name}}</span>
              <span v-if="item.new_agency_fee||item.new_agency_fee===0">{{item.original_agency_fee}}-->{{item.new_agency_fee}}</span>
              <span v-for="good in item.delete_good_list" :key="good.id">
                {{good.good_name+'/'+good.good_unit+'/'+good.good_spec}}~数量：{{good.good_num}}~ 单价：{{good.price_out}}~ 总价:{{good.good_num*good.price_out}}
              </span>
              <span v-for="addgood in item.add_good_list" :key="addgood.id">
                {{addgood.good_name+'/'+addgood.good_unit+'/'+addgood.good_spec}}~数量：{{addgood.num}}~ 单价：{{addgood.price_out}}~ 总价:{{addgood.num*addgood.price_out}}
              </span>
            </div>
          </span>
          <span slot="adjustStatusSlot" slot-scope="text">
            <a-tag :color="adjustStatusColor(text)" style="margin-bottom:2px;">
              {{ adjustStatusName(text) }}
            </a-tag>
          </span>
          <span slot="action" slot-scope="text, record">
            <template>
              <a v-if="record.adjust_status === 'business_apply'" @click="showApplyAdjust(record)">申请</a>
              <a-divider type="vertical" />
              <a v-if="record.adjust_status === 'business_apply'||record.adjust_status === 'cancel'" @click="handleDelete(record)">删除</a>
            </template>
          </span>
        </s-table>
        <a-modal
          :visible="applyAdjustVisible"
          :confirmLoading="applyAdjustLoading"
          :width="1200"
          :maskClosable="false"
          title="申请调整"
          @cancel="applyAdjustVisible = false"
          @ok="submitApplyAdjust">
          <a-form-model>
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-model-item label="业务编号">
                  <span style="display:block">{{ selectedAdjustOrder.serial_num }}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="16">
                <a-form-model-item label="调整事项">
                  <div v-for="item in selectedAdjustOrder.sale_adjust_details" :key="item.id">
                    <a-tag :color="adjustOrderTypeColor(item.adjust_type)" style="margin-bottom:2px;">
                      {{ item.adjust_type }}
                    </a-tag>
                    <span>{{item.original_invoices}}</span>
                    <span v-if="item.new_customer_name">{{item.original_customer_name}}-->{{item.new_customer_name}}</span>
                    <span v-if="item.new_agency_fee||item.original_agency_fee">{{item.original_agency_fee}}-->{{item.new_agency_fee}}</span>
                    <span v-for="good in item.delete_good_list" :key="good.id">
                      {{good.good_name+'/'+good.good_unit+'/'+good.good_spec}}~数量：{{good.good_num}}~ 单价：{{good.price_out}}~ 总价:{{good.good_num*good.price_out}}
                    </span>
                    <span v-for="addgood in item.add_good_list" :key="addgood.id">
                      {{addgood.good_name+'/'+addgood.good_unit+'/'+addgood.good_spec}}~数量：{{addgood.num}}~ 单价：{{addgood.price_out}}~ 总价:{{addgood.num*addgood.price_out}}
                    </span>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <a-form-model layout="vertical" :model="applyAdjustForm" :rules="applyAdjustRules" ref="applyAdjustForm">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-model-item label="利润是否减少" prop="effect_profit">
                  <a-select
                    style="width: 100%"
                    v-model="applyAdjustForm.effect_profit"
                  >
                    <a-select-option key="1">是</a-select-option>
                    <a-select-option key="0">否</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="财务SAP编号">
                  <a-input v-model.trim="applyAdjustForm.fms_sap_num" placeholder="请先与财务确认是否需要填写"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-divider orientation="left" style="font-weight:700">更正事项</a-divider>
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-model-item label="调整事项" prop="adjust_type">
                  <a-select
                    placeholder="请选择调整事项"
                    style="width: 100%"
                    v-model="applyAdjustForm.adjust_type"
                  >
                    <a-select-option v-for="op in adjustTypeList" :key="op.value">{{ op.value}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="16">
                <a-form-model-item label="调账说明" prop="adjust_description">
                  <a-input v-model.trim="applyAdjustForm.adjust_description"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-divider orientation="left" style="font-weight:700">更正原因</a-divider>
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-model-item label="更正原因" prop="adjust_type">
                  <a-select
                    placeholder="请选择更正原因"
                    style="width: 100%"
                    v-model="applyAdjustForm.adjust_reason"
                  >
                    <a-select-option v-for="op in adjustReasonList" :key="op.value">{{ op.value}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="16">
                <a-form-model-item label="更正原因说明" prop="reason_description">
                  <a-input v-model.trim="applyAdjustForm.reason_description"/>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-modal>
      </a-card>
    </keep-alive>
  </div>
</template>

<script>
import { STable } from '@/components';
import moment from 'moment';
import {
  pageListSaleAdjust,
  initSaleAdjust,
  submitSaleApplyAdjust,
  deleteSaleAdjustOrder
} from '@/api/wms/sale'

export default {
  name: 'SaleAdjust',
  components: {
    STable
  },
  data() {
    return {
      queryParam: {},
      selectedRowKeys: [],
      supplierOps: [],
      adjustTypeOps: [],
      adjustStatusOps: [],
      billTypeOps: [],
      columns: [
        {
          title: '#',
          align: 'center',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '流水号',
          dataIndex: 'id'
        },
        {
          title: '业务单号',
          dataIndex: 'serial_num'
        },
        {
          title: '调整项目',
          dataIndex: 'adjust_types',
          scopedSlots: { customRender: 'adjustTypeInfoSlot' }
        },
        {
          title: '调整状态',
          dataIndex: 'adjust_status',
          scopedSlots: { customRender: 'adjustStatusSlot' }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '申请时间',
          dataIndex: 'apply_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: (parameter) => {
        return pageListSaleAdjust(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      applyAdjustVisible: false,
      applyAdjustLoading: false,
      applyAdjustForm: {
        department: 2,
        audit_record_id: null,
        business_adjust_id: null,
        effect_profit: null,
        // finance_type: 'charge',
        adjust_type: null,
        adjust_description: null,
        adjust_reason: null,
        reason_description: null,
        fms_sap_num: null
      },
      applyAdjustRules: {
        business_order_num: [{ required: true, message: '请填写业务编号', trigger: 'change' }],
        effect_profit: [{ required: true, message: '请确认利润是否减少', trigger: 'change' }],
        adjust_type: [{ required: true, message: '请选择调整事项', trigger: 'change' }],
        adjust_reason: [{ required: true, message: '请选择更正原因', trigger: 'change' }],
        adjust_description: [{ required: true, message: '请输入调账说明', trigger: 'blur' }],
        reason_description: [{ required: true, message: '请输入更正原因说明', trigger: 'blur' }]
      },
      adjustTypeList: [
        { value: '更改金额' },
        { value: '更改客户供应商' },
        { value: '更改币种' },
        { value: '更改费用项目' },
        { value: '其他' }
      ],
      adjustReasonList: [
        { value: '客户供应商原因' },
        { value: '业务经办人原因' },
        { value: '系统原因' },
        { value: '其他原因' }
      ],
      selectedAdjustOrder: {}
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === 'SaleAdjust' && this.queryParam) {
        newRoute.meta.title = '调整业务'
        this.$nextTick(_ => {
          this.$refs.table.refresh(newRoute.query.isEdit)
        });
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      initSaleAdjust().then(res => {
        this.adjustTypeOps = res.ADJUST_TYPE_LIST
        this.adjustStatusOps = res.ADJUST_STATUS_LIST
        this.billTypeOps = res.BILL_TYPE_LIST
      })
    },
    adjustStatusColor(value) {
      switch (value) {
        case 'business_apply':
          return '#faad14'
        case 'handle_finish':
          return '#4cd964'
        case 'cancel':
          return ''
        default:
          return '#1890ff'
      }
    },
    adjustStatusName(value) {
      for (let i = 0; i < this.adjustStatusOps.length; i++) {
        if (this.adjustStatusOps[i].code === value) {
          return this.adjustStatusOps[i].name
        }
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['create_begin_time'] = startDate
        this.queryParam['create_end_time'] = endDate
      } else {
        this.queryParam['create_begin_time'] = null
        this.queryParam['create_end_time'] = null
      }
    },
    onApplyDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['apply_begin_time'] = startDate
        this.queryParam['apply_end_time'] = endDate
      } else {
        this.queryParam['apply_begin_time'] = null
        this.queryParam['apply_end_time'] = null
      }
    },
    handleCreate() {
      this.$router.push({
        name: 'SaleAdjustCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.$router.push({
        name: 'SaleAdjustCUR',
        params: record
      })
    },
    handleDelete(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteSaleAdjustOrder({ adjust_order_id: record.id }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true);
          });
        }
      });
    },
    showApplyAdjust(record) {
      this.selectedAdjustOrder = record;
      this.applyAdjustForm = {
        department: 2,
        business_adjust_order_id: record.id,
        effect_profit: null,
        adjust_type: null,
        adjust_description: null,
        adjust_reason: null,
        reason_description: null,
        fms_sap_num: null
      }
      this.applyAdjustVisible = true
    },
    submitApplyAdjust() {
      this.$refs.applyAdjustForm.validate(valid => {
        if (valid) {
          this.applyAdjustLoading = true
          submitSaleApplyAdjust(this.applyAdjustForm).then(_ => {
              this.applyAdjustLoading = false
              this.applyAdjustVisible = false
              this.$notification['success']({
                message: '提示',
                description: '保存成功'
              })
              this.$refs.table.refresh(true)
            })
            .catch(_ => {
              this.applyAdjustLoading = false
            })
        }
      })
    },
    adjustOrderTypeColor(value) {
      switch (value) {
        case '新增费用':
          return 'orange'
        case '删除费用':
          return 'red'
        case '修改客户':
          return 'purple'
        default:
          return 'blue'
      }
    }
  }
}
</script>

<style scoped>

</style>
